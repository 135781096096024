import { useGLTF, Environment, ContactShadows } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import * as THREE from 'three';

interface MugProps {
  rotationY: number;
  image: Blob | string;
  selectedColor: string;
  isColoredMug: boolean;
  snapClicked?: any;
  setSnapImages?: any;
  frontDesign?: string;
  setLoading?: (loading: boolean) => void;
}

const Mug3d = forwardRef((props: MugProps, ref) => {
  const { nodes, materials, scene } = useGLTF('/models/11ozMug.glb');
  const { camera, gl, scene: threeScene, size } = useThree();
  const {
    setSnapImages,
    rotationY,
    image,
    isColoredMug,
    selectedColor,
    frontDesign,
    setLoading,
  } = props;
  const [texture, setTexture] = useState<THREE.Texture | null>(null);
  const groupRef = useRef<THREE.Group>(null);
  // Load texture
  useEffect(() => {
    if (image instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const img = new Image();
          img.src = e.target.result as string;
          img.onload = () => {
            const newTexture = new THREE.Texture(img);
            newTexture.colorSpace = THREE.SRGBColorSpace;
            newTexture.needsUpdate = true;
            setTexture(newTexture);
          };
        }
      };
      reader.readAsDataURL(image);
    } else {
      const loader = new THREE.TextureLoader();
      loader.load(
        image,
        (loadedTexture) => {
          loadedTexture.colorSpace = THREE.SRGBColorSpace;
          loadedTexture.needsUpdate = true;
          setTexture(loadedTexture);
        },
        undefined,
        (error) => {},
      );
    }
  }, [frontDesign, image]);

  // Setup scene lights
  useEffect(() => {
    if (scene) {
      // const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
      // scene.add(ambientLight);

      // Add a Hemisphere light for soft, even lighting across the model
      const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 10);

      hemisphereLight.position.set(10, 10, 10); // Place it above the model
      // scene.add(hemisphereLight);

      // Optional: You can still keep a low-intensity directional light for subtle shadows
      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
      directionalLight.position.set(5, 5, 5).normalize();
      directionalLight.castShadow = false;
      directionalLight.shadow.mapSize.width = 1024;
      directionalLight.shadow.mapSize.height = 1024;
      // scene.add(directionalLight);

      scene.traverse((object) => {
        if ((object as THREE.Mesh).isMesh) {
          const mesh = object as THREE.Mesh;
          if (mesh.name === 'Mug_11oz_LOW_HI001_2') {
            const material = mesh.material as
              | THREE.MeshStandardMaterial
              | THREE.MeshBasicMaterial;
            if (!isColoredMug) {
              material.color = new THREE.Color(0xffffff);
            } else {
              const colorName = selectedColor
                .split('/')[1]
                .trim()
                .replace(/\s+/g, '')
                .toLowerCase();
              material.color = new THREE.Color(colorName);
              material.needsUpdate = true;
            }
          } else if (object.name === 'Mug_11oz_LOW_HI001_1') {
            const material = mesh.material as
              | THREE.MeshStandardMaterial
              | THREE.MeshBasicMaterial;
            if (material && texture) {
              material.map = texture;
              material.map.flipY = false;
              material.needsUpdate = true;
            }
          }
        }
      });
      if (setLoading) setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColoredMug, selectedColor, scene, texture]);

  useImperativeHandle(ref, () => ({
    takeScreenshot: () => {
      if (!groupRef.current) return;
      const renderer = gl as THREE.WebGLRenderer;
      const originalPixelRatio = renderer.getPixelRatio();
      const originalSize = renderer.getSize(new THREE.Vector2());
      const boundingBox = new THREE.Box3().setFromObject(groupRef.current);
      const center = boundingBox.getCenter(new THREE.Vector3());
      const originalCameraPosition = camera.position.clone();
      const originalCameraTarget = center.clone();
      const originalClearColor = renderer.getClearColor(new THREE.Color());
      const zoomFactor = 0.8;
      camera.position.set(
        center.x + (camera.position.x - center.x) * zoomFactor,
        center.y + (camera.position.y - center.y) * zoomFactor,
        center.z + (camera.position.z - center.z) * zoomFactor,
      );
      camera.lookAt(center);
      renderer.setClearColor(new THREE.Color(0xffffff), 1);
      renderer.render(threeScene, camera);

      renderer.setPixelRatio(window.devicePixelRatio * 2.6);
      renderer.setSize(size.width * 2.6, size.height * 2.6);

      renderer.render(threeScene, camera);
      const dataURL = renderer.domElement.toDataURL();

      renderer.setPixelRatio(originalPixelRatio);
      renderer.setSize(originalSize.width, originalSize.height);
      renderer.setClearColor(originalClearColor, 0);
      camera.position.copy(originalCameraPosition);
      camera.lookAt(originalCameraTarget);

      setSnapImages(dataURL, props.selectedColor);
    },
  }));

  useEffect(() => {
    return () => {
      scene.traverse((object) => {
        if ((object as THREE.Mesh).isMesh) {
          const mesh = object as THREE.Mesh;
          mesh.geometry.dispose();
          if (mesh.material instanceof THREE.Material) {
            mesh.material.dispose();
          }
        }
      });
    };
  }, [scene]);
  useEffect(() => {
    const group = groupRef.current;
    return () => {
      // Clean up resources
      if (group) {
        group.traverse((object) => {
          if ((object as THREE.Mesh).isMesh) {
            const mesh = object as THREE.Mesh;
            mesh.geometry.dispose();
            if (Array.isArray(mesh.material)) {
              mesh.material.forEach((material) => material.dispose());
            } else {
              mesh.material.dispose();
            }
          }
        });
      }
    };
  }, []);
  return (
    <>
      <Environment preset="sunset" />
      <ContactShadows
        position={[0, -1.4, 0]}
        opacity={5.5}
        scale={10}
        blur={2.5}
        far={4}
      />
      <group
        ref={groupRef}
        rotation={[0, (rotationY * Math.PI) / 180, 0]}
        dispose={null}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mug_11oz_LOW_HI001 as THREE.Mesh).geometry}
          material={materials.Ceramic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mug_11oz_LOW_HI001_1 as THREE.Mesh).geometry}
          material={materials['#placeholder_front']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={(nodes.Mug_11oz_LOW_HI001_2 as THREE.Mesh).geometry}
          material={materials.Accent}
        />
      </group>
    </>
  );
});

useGLTF.preload('/models/11ozMug.glb');
export default Mug3d;
