import React, { useMemo } from 'react';
// @ts-ignore
import { NewSidebar } from 'everbee-common-components';
import {
  CatalogIcon,
  ProductIcon,
  OrderIcon,
  ManageBillingIcon,
  LibraryIcon,
  HelpIcon,
  BeeIcon,
  ShopIcon,
} from '../../assets/icons';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getUserData,
  selectIsUserLoading,
  logout,
} from '../../store/slices/authSlice';
import {
  useChangeShopMutation,
  useLazyStripeManageBillingSessionQuery,
} from '../../store/services/user';
import Loader from '../common/loader';
import { routeNames } from '../../constants/routesPath';
import {
  getUtilityData,
  setShowConnetModalForAddNewShop,
} from '../../store/slices/utilitySlice';
import { openLinkInNewTab } from '../../helper/openLinkInNewTab';
import { useSelector } from 'react-redux';

interface SidebarProps {
  isExpanded: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isExpanded, toggleSidebar }) => {
  const { userInfo } = useAppSelector(getUserData);
  const [changeShop, { isLoading }] = useChangeShopMutation();
  const [manageBilling] = useLazyStripeManageBillingSessionQuery();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isInvalidGrantFromEtsy, showConnetModalForAddNewShop } =
    useAppSelector(getUtilityData);

  const isUserLoading = useSelector(selectIsUserLoading);

  const connectedSalesChannels = useMemo(() => {
    return userInfo.salesChannels?.map(
      (channel: {
        salesChannelShopId: number;
        salesChannelShopName: string;
        shopImg: string;
      }) => ({
        id: channel.salesChannelShopId,
        name: channel.salesChannelShopName,
        isChecked: channel.salesChannelShopId === userInfo.storeId,
        salesChannelLogo: channel.shopImg ? channel.shopImg : ShopIcon,
        salesCount: userInfo.sidebarOrderCount,
      }),
    );
  }, [userInfo.salesChannels, userInfo.storeId, userInfo.sidebarOrderCount]);
  const [searchParams] = useSearchParams();

  const isErrorMessageFromBackend = searchParams.get('message');

  const hasConnectedSalesChannels =
    connectedSalesChannels &&
    connectedSalesChannels.length > 0 &&
    !isInvalidGrantFromEtsy &&
    !isErrorMessageFromBackend &&
    !showConnetModalForAddNewShop;

  function clearGistCookies() {
    const cookieNames = [
      'gist_identified_4rtjrhbh',
      'gist_identified_7tn4opfe',
      'gist_id_4rtjrhbh',
      'gist_id_7tn4opfe',
    ];

    // Loop through the cookie names and delete each cookie
    cookieNames.forEach((cookieName) => {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  }
  const SidebarProps = {
    collapseType: 'button',
    isExpanded: isExpanded,
    handleExpansion: () => {
      toggleSidebar();
    },
    isExtension: false,
    productSwitcher: [
      {
        name: 'EverBee Research',
        logo: BeeIcon,
        url: process.env.REACT_APP_RESEARCH_APP,
        description: 'Find the best products to sell on Etsy',
        default: false,
      },
      {
        name: 'EverBee Email',
        logo: BeeIcon,
        url: process.env.REACT_APP_EMAIL_APP,
        description: 'Get more sales on autopilot',
        default: false,
      },
      {
        name: 'EverBee Print',
        logo: BeeIcon,
        url: process.env.REACT_APP_PRINT_APP,
        description: 'Sell print-on-demand products on your shop',
        default: true,
        badges: ['Beta'],
      },
      ...(userInfo.appSwitcherFlags?.ibs
        ? [
            {
              name: 'EverBee Store',
              logo: BeeIcon,
              url: process.env.REACT_APP_STORE_APP,
              description: 'Make your own website and sell, sell, sell!',
              default: false,
              badges: ['Beta'],
            },
          ]
        : []),
    ],
    header: {
      productLogo: isExpanded
        ? 'https://db2l6eppot2a3.cloudfront.net/print-logo.svg'
        : BeeIcon,
      handleLogoClick: () => {
        navigate('/');
      },
    },
    salesChannels: {
      loading: isUserLoading,
      isMasked: false,
      handleMask: () => {},
      handleAddNewSalesChannel: () => {
        if (hasConnectedSalesChannels) {
          dispatch(setShowConnetModalForAddNewShop(true));
        }
      },
      handleSalesChannelClick: async (shop: { id: number }) => {
        await changeShop({ shopId: shop.id })
          .unwrap()
          .then((res: any) => {
            if (res.success) {
              navigate(0);
            }
          })
          .catch(() => {});
      },
      handleSalesChannelTextClick: () => {},
      connectedSalesChannels,
      // ...(userInfo.salesChannels?.length > 0 && { connectedSalesChannels }),
    },
    menuItems: [
      {
        menuIcon: CatalogIcon,
        menuName: 'Catalog',
        handleMenuClick: () => {
          if (hasConnectedSalesChannels) {
            navigate(routeNames.catalog);
          }
        },
        tooltipText: 'Catalog',
        isActive: location.pathname === routeNames.catalog,
      },
      {
        menuIcon: ProductIcon,
        menuName: 'My Products',
        handleMenuClick: () => {
          if (hasConnectedSalesChannels) {
            navigate(routeNames.myProducts);
          }
        },
        tooltipText: 'My Products',
        isActive: location.pathname === routeNames.myProducts,
      },
      {
        menuIcon: OrderIcon,
        menuName: 'Orders',
        handleMenuClick: () => {
          if (hasConnectedSalesChannels) {
            navigate(routeNames.orders);
          }
        },
        tooltipText: 'Orders',
        isActive: location.pathname === routeNames.orders,
      },
      {
        menuIcon: LibraryIcon,
        menuName: 'My Library',
        handleMenuClick: () => {
          if (hasConnectedSalesChannels) {
            navigate(routeNames.myLibrary);
          }
        },
        tooltipText: 'My Library',
        isActive: location.pathname === routeNames.myLibrary,
      },
      ...(userInfo.stripeCustomerId
        ? [
            {
              menuIcon: ManageBillingIcon,
              menuName: 'Manage Billing',
              handleMenuClick: async () => {
                if (hasConnectedSalesChannels) {
                  try {
                    const { data } = await manageBilling().unwrap();
                    if (data.url) {
                      openLinkInNewTab(data.url);
                    }
                  } catch (error) {
                    console.error('Error managing billing:', error);
                  }
                }
              },
              tooltipText: 'Manage Billing',
              isActive: false,
            },
          ]
        : []),
    ],
    footer: {
      planUpgrade: userInfo.plan.toLowerCase().includes('hobby')
        ? {
            handleClick: () => {
              openLinkInNewTab(`${process.env.REACT_APP_RESEARCH_APP}/pricing`);
            },
          }
        : null,
      userData: {
        name: userInfo.name,
        image: userInfo.userImage,
        email: userInfo.email,
        plan: userInfo.plan,
      },
      profileMenuItems: [
        {
          itemName: 'Help center',
          icon: HelpIcon,
          handleMenuClick: () => {
            openLinkInNewTab('https://help.everbee.io');
          },
        },
      ],
      handleLogout: () => {
        clearGistCookies();
        dispatch(logout());
      },
    },
  };

  return (
    <>
      {isLoading && <Loader />}
      <NewSidebar props={SidebarProps} />
    </>
  );
};

export default Sidebar;
