import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppSwitcher,
  SalesChannels,
  UserInfoResponse,
} from './../../interface/user-interface';

import { RootState } from '..';
import { getCookie, setCookie } from '../../helper/cookies';
import { userAPI } from '../services/user';

interface User {
  id: string;
  everbeeProdUserId: string;
  storeId: string | null;
  name: string;
  storeName: string | null;
  email: string;
  plan: string;
  stripeCustomerId: string | null;
  salesChannelCurrency: string | null;
  salesChannelEtsyUserId: number | null;
  paymentFailedForOrders: boolean;
  orderFailureReason: string;
  paymentMethodId: string;
  orderCount: number;
  sidebarOrderCount: number;
  isGrowth?: boolean;
  salesChannels?: SalesChannels[];
  appSwitcherFlags?: AppSwitcher;
  userImage: string | null;
  isProductionPartnerCreated: boolean;
}

export interface AuthInitialState {
  userInfo: User;
  token: string;
  isAuth: boolean;
  isGrowth: boolean;
  stripeCustomerId: string | null;
}

const initalUserData: User = {
  email: '',
  everbeeProdUserId: '',
  id: '',
  name: '',
  orderCount: 0,
  sidebarOrderCount: 0,
  orderFailureReason: '',
  paymentFailedForOrders: false,
  paymentMethodId: '',
  plan: '',
  salesChannelCurrency: null,
  salesChannelEtsyUserId: null,
  storeId: null,
  storeName: null,
  stripeCustomerId: null,
  isGrowth: false,
  salesChannels: [],
  appSwitcherFlags: { ibp: false, ibs: false },
  userImage: '',
  isProductionPartnerCreated: false,
};

const initialState: AuthInitialState = {
  userInfo: initalUserData,
  token: getCookie(process.env.REACT_APP_AUTH_COOKIE_NAME as string) ?? '',
  isAuth: !!getCookie(process.env.REACT_APP_AUTH_COOKIE_NAME as string),
  isGrowth: false,
  stripeCustomerId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUserInfo: (state, { payload }: PayloadAction<UserInfoResponse>) => {
      const {
        email,
        paymentFailedForOrders,
        everbeeProdUserId,
        _id,
        name,
        orderCount,
        sidebarOrderCount,
        orderFailureReason,
        paymentMethodId,
        plan,
        salesChannel,
        salesChannels,
        stripeCustomerId,
        isGrowth,
        appSwitcherFlags,
      } = payload;
      const info: Partial<User> = {
        email,
        paymentFailedForOrders,
        everbeeProdUserId,
        id: _id,
        name,
        paymentMethodId,
        orderFailureReason,
        orderCount,
        sidebarOrderCount,
        plan,
        stripeCustomerId,
        isGrowth,
        salesChannels,
        appSwitcherFlags,
        ...(salesChannel && {
          ...salesChannel,
        }),
      };
      state.userInfo = {
        ...state.userInfo,
        ...info,
      };
    },
    setIsProductionPartnerCreated: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.userInfo.isProductionPartnerCreated = payload;
    },
    logout: (state) => {
      state.userInfo = {} as User;
      state.token = '';
      state.isAuth = false;
      state.isGrowth = false;
      state.stripeCustomerId = null;
      window.location.href = `${process.env.REACT_APP_SSO_FRONTEND_APP}/logout?redirect_to=${process.env.REACT_APP_PRINT_APP}`;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userAPI.endpoints.getUserInfo.matchFulfilled,
      (state, { payload }) => {
        setCookie(
          process.env.REACT_APP_AUTH_COOKIE_NAME as string,
          payload.everbeeToken,
        );
        // gistIdentify(payload.email);
        const infoToSave = {
          id: payload._id,
          storeId: payload.salesChannel?.salesChannelShopId || null,
          salesChannelCurrency:
            payload?.salesChannel?.salesChannelCurrency || null,
          name: payload.name,
          storeName: payload.salesChannel?.salesChannelShopName || null,
          email: payload.email,
          plan: payload.plan,
          stripeCustomerId: payload.stripeCustomerId,
          everbeeProdUserId: payload.everbeeProdUserId,
          salesChannelEtsyUserId:
            payload?.salesChannel?.salesChannelEtsyUserId || null,
          paymentMethodId: payload.paymentMethodId,
          paymentFailedForOrders: payload.paymentFailedForOrders,
          orderFailureReason: payload.orderFailureReason,
          orderCount: payload.orderCount,
          sidebarOrderCount: payload.sidebarOrderCount,
          isGrowth: payload.isGrowth,
          salesChannels: payload.salesChannels,
          appSwitcherFlags: payload.appSwitcherFlags,
          userImage: payload.userImage,
          isProductionPartnerCreated: payload?.isProductionPartnerCreated,
        };
        return {
          ...state,
          isAuth: true,
          token: payload.everbeeToken,
          userInfo: infoToSave,
          isGrowth: payload.isGrowth,
          stripeCustomerId: payload.stripeCustomerId,
        };
      },
    );
  },
});
export const selectIsUserLoading = (state: RootState) =>
  userAPI.endpoints.getUserInfo.select()(state).isLoading;
export const { updateUserInfo, logout, setIsProductionPartnerCreated } =
  authSlice.actions;
export default authSlice.reducer;
export const getUserData = (state: RootState) => state.auth;
