import { Canvas } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { colors } from '../../../../assets/colors';
export const applyMugCanvas = (
  canvas: Canvas,
  data: any,
  boundaryDiv: HTMLDivElement,
) => {
  const extraAreaInPXForInactiveFeel = 25;
  const inactiveAreaSize = 20;
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  boundaryDiv.style.boxShadow = `inset 0 0 0 ${inactiveAreaSize}px #F3F9FF`;
  boundaryDiv.style.top = `${extraAreaInPXForInactiveFeel}px`;
  boundaryDiv.style.left = `${extraAreaInPXForInactiveFeel}px`;
  boundaryDiv.style.width = `${canvasWidth - (extraAreaInPXForInactiveFeel * 2)}px`;
  boundaryDiv.style.height = `${canvasHeight - (extraAreaInPXForInactiveFeel * 2)}px`;
  boundaryDiv.style.outline = '4px solid #3D98F2'
  boundaryDiv.className = 'editor-design-inactive-area';

  let artBoard: any = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel - 4,
    top: extraAreaInPXForInactiveFeel - 4,
    width: canvasWidth - (extraAreaInPXForInactiveFeel * 2) + 4,
    height: canvasHeight - (extraAreaInPXForInactiveFeel * 2) + 4,
    stroke: colors.grey[700],
    fill: 'black',
    selectable: false,
    hoverCursor: 'default',
    evented: false,
    opacity: 1,
    name: 'boundary-rect',
  });
  canvas.clipPath = artBoard;
  canvas.remove(artBoard);
  canvas.requestRenderAll();

  var centralBox = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel + inactiveAreaSize,
    top: extraAreaInPXForInactiveFeel + inactiveAreaSize,
    width: canvasWidth - (extraAreaInPXForInactiveFeel * 2) - (inactiveAreaSize * 2),
    height: canvasHeight - (extraAreaInPXForInactiveFeel * 2) - (inactiveAreaSize * 2),
    fill: '',
    selectable: false,
    name: 'border-canvas',
    stroke: 'black',
    strokeWidth: 1,
    strokeDashArray: [10, 10],
    evented: false,
    backgroundColor: 'white'
  });
  canvas.add(centralBox);
  canvas.sendToBack(centralBox);
  canvas.requestRenderAll();

  const borderRect = new fabric.Rect({
    left: extraAreaInPXForInactiveFeel - 2,
    top: extraAreaInPXForInactiveFeel - 2,
    width: canvasWidth - (extraAreaInPXForInactiveFeel * 2) + 2,
    height: canvasHeight - (extraAreaInPXForInactiveFeel * 2) + 2,
    fill: '',
    stroke: '#3D98F2',
    strokeWidth: 4,
    selectable: false,
    evented: false,
    name: 'blue-mug-order',
  });
  canvas.add(borderRect);
  canvas.sendToBack(borderRect);
  canvas.requestRenderAll();
};
