import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import Button from '../../common/button';
import { EditIcon } from '../../../assets/icons';
import MuiDataTable from '../../table/MuiDataTable';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getDetails,
  updateEditField,
} from '../../../store/slices/detailsSlice';
import { getVariantColumns } from './variantsColumns';
import { toast } from 'react-toastify';
import { fetchCurrencyRates } from '../../../utils/currency-converter';

interface Props {
  currentCurrency?: string;
  shopCurrency?: string;
  height?: string;
}

const VariantTable: React.FC<Props> = ({
  currentCurrency,
  shopCurrency,
  height,
}) => {
  const { productDetails, selectedRows } = useAppSelector(getDetails);
  const dispatch = useAppDispatch();
  const [multiplier, setMultiplier] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCurrencyRates(currentCurrency, shopCurrency);
        setMultiplier(data);
      } catch {
        setMultiplier(1);
      }
    };
    fetchData();
  }, [shopCurrency, currentCurrency]);

  const columns = useMemo(() => {
    return getVariantColumns(currentCurrency, shopCurrency);
  }, [currentCurrency, shopCurrency]);

  const rowData = useMemo(() => {
    const data: any[] = [];
    productDetails.variants.forEach((variant) => {
      data.push({
        id: variant.id,
        color: variant.properties.find((prop) => prop.propertyName === 'color')
          ?.propertyValue,
        size: variant.properties.find((prop) => prop.propertyName === 'size')
          ?.propertyValue,
        inStock: variant.inStock ? 'In Stock' : 'Out of Stock',
        cost: variant.cost.toFixed(2),
        mrsp: `$ ${variant.mrsp.toFixed(2)}`,
        profit:
          variant.mrsp > 0
            ? `$ ${(variant.mrsp - variant.cost).toFixed(2)}`
            : '$ 0',
        margin:
          variant.mrsp > 0
            ? `${(((variant.mrsp - variant.cost) / variant.cost) * 100).toFixed(
                2,
              )} %`
            : '0 %',
        mrspInCurrency: `${shopCurrency} ${(variant.mrsp * multiplier).toFixed(
          2,
        )}`,
        profitInCurrency: `${shopCurrency} ${(
          (variant.mrsp - variant.cost) *
          multiplier
        ).toFixed(2)}`,
      });
    });
    return data;
  }, [productDetails.variants, shopCurrency, multiplier]);

  const openEditModal = (value: 'profit' | 'margin' | 'none' | 'mrsp') => {
    if (selectedRows.length > 0) {
      dispatch(updateEditField({ fieldName: value }));
    } else {
      toast.warn('Please select variants');
    }
  };

  return (
    <StyledWrapper height={height}>
      <FlexBox justifyContent="space-between" className="container">
        <div className="header">Variants</div>
        <FlexBox gap="16px" alignItems="center">
          <Button
            icon={EditIcon}
            appearance="secondary"
            label="Edit price"
            onClick={() => openEditModal('mrsp')}
            size="medium"
          />
          <Button
            icon={EditIcon}
            appearance="secondary"
            label="Edit profit"
            onClick={() => openEditModal('profit')}
            size="medium"
          />
          <Button
            icon={EditIcon}
            appearance="secondary"
            label="Edit margin"
            onClick={() => openEditModal('margin')}
            size="medium"
          />
        </FlexBox>
      </FlexBox>
      <MuiDataTable
        rowData={rowData}
        columns={columns}
        // treeView={true}
      />
    </StyledWrapper>
  );
};

export default VariantTable;

const StyledWrapper = styled.div<{ height?: string }>`
  width: 100%;
  height: ${(props) => props.height ?? '340px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.grey[300]};
  border-radius: 6px;
  .container {
    padding: 12px 16px;
    align-items: baseline;
  }
  .header {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    font-style: normal;
  }
`;
