import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { colors } from '../../assets/colors';
import RightSideBar from './right-sidebar';
import { FlexBox } from '../common/wrappers';
import { ImagePlaceholder } from '../common/image-placeholder';
import {
  Crown,
  DropzonePlaceholder,
  RightArrowIcon,
  ProductMockups,
} from '../../assets/icons';
import ImageBox from '../common/image-box';
import TopBar from '../editor/subComponents/top-bar';
import {
  setSelectedImageArray,
  setClickedImage,
  updateMockupsInBeginning,
  setSelectedImages,
  updateMockups,
  getMockups,
  setUpdatedMockupImage,
} from '../../store/slices/mockupSlice';
import React, { useEffect, useRef, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  MockupData,
  MockupVariant,
  SelectedImage,
} from '../../interface/mockups-interface';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  increaseExtraMockupCount,
  setCatalogueId,
  setModelNumber,
  setPrintProviderId,
  setProductId,
} from '../../store/slices/utilitySlice';
import { useGetRawProductDetailsQuery } from '../../store/services/products';
import Loader from '../common/loader';
import { useDropzone } from 'react-dropzone';
import { useAddImageToMyMockupsMutation } from '../../store/services/my-mockups';
import { Drawer } from '@mui/material';
import Button from '../common/button';
import { socket } from '../../helper/socket';
import { TabletProps } from '../../interface';
import amplitude from 'amplitude-js';
import {
  getMinimumAndMaximumMRSPFromVariants,
  getUniqueColorsOrColorHexFromVariants,
} from '../../helper/getMinimumAndMaximumMRSPFromVariants';
import { convertB64toBlob } from '../../helper/convertB64toBlob';
import { useAppSelector } from '../../store/hooks';
import { getUserData } from '../../store/slices/authSlice';
import { useUploadMockupsMutation } from '../../store/services/products';
import { routeNames } from '../../constants/routesPath';
import { SingleProductVariant } from '../../interface/product-interface';
import { useGetSingleCatalogProductForEditorQuery } from '../../store/services/catalog';
import { ProductType } from '../editor';
import { combineCatalogVariantAndProductVariant } from './utils';
import { VariantsV2 } from '../../interface/catalog-interface-v2';
const Mockups: React.FC<TabletProps> = ({ isTablet }) => {
  const [isHighResolutionMockupLoading, setIsHighResolutionMockupLoading] =
    useState<boolean>(false);
  const [openRightSidebar, setOpenRightSidebar] = useState(false);
  const [openButtonDrawer, setOpenButtonDrawer] = useState(false);
  const [printSides, setPrintSides] = useState<string[]>([]);
  const [mockupVariants, setMockupVariants] = useState<MockupVariant[]>([]);

  const navigate = useNavigate();
  const { userInfo } = useAppSelector(getUserData);
  const [uploadMockupsMutation, { isLoading: isMockupsUploading }] =
    useUploadMockupsMutation();
  const dispatch = useDispatch();
  const { productId, modelNumber } = useParams();
  const location = useLocation();
  const { data, isLoading, isSuccess } = useGetRawProductDetailsQuery({
    productId: productId,
    fromMockups: true,
    ...(location.state?.triggerUpdate ? { triggerUpdate: true } : {}),
  });

  const productData = data?.data;
  const { data: catalogData } = useGetSingleCatalogProductForEditorQuery(
    {
      catalogProductId: productData?.catalogId,
      printProviderId: productData?.printProviderId,
      fromEditor: 'true',
    },
    {
      skip: isLoading,
    },
  );
  useEffect(() => {
    if (catalogData && productData) {
      const variants = combineCatalogVariantAndProductVariant(
        productData.variants as VariantsV2[],
        catalogData.variants,
      );

      const printAreas = catalogData.catalogProductInfo.printAreas.find(
        (printArea) =>
          printArea.printProviderId === productData?.printProviderId,
      );
      const printSides = printAreas?.sides.map((e) => e.side) || [];

      setPrintSides(printSides);
      setMockupVariants(variants);
      dispatch(setUpdatedMockupImage(productData.images));
      dispatch(setPrintProviderId(productData.printProviderId));
      dispatch(setCatalogueId(productData.catalogId));
    }
  }, [catalogData, dispatch, productData]);

  const [addToMyMockups, { isLoading: isUploading }] =
    useAddImageToMyMockupsMutation();
  const { selectedImages, clickedImage, updatedMockupImage } =
    useAppSelector(getMockups);
  const handleClickedImage = (imgId: number | string, imageUrl: string) => {
    dispatch(
      setClickedImage({
        imgId,
        imageUrl,
      }),
    );
  };
  const uploadMockups = async (naviagteToDetails: boolean) => {
    socket.emit('join', userInfo.id);
    var blobImages = convertB64toBlob(
      selectedImages.filter((image: SelectedImage) => !image.isMymockup),
    );
    var generatedImages = selectedImages.filter(
      (image: SelectedImage) => !image.isMymockup,
    );
    const myMockups: any[] = [];
    selectedImages.forEach((image: SelectedImage, index: number) => {
      if (image.isMymockup) {
        myMockups.push({
          imageUrl: image.mockupImage,
          id: image.mockupId,
          index,
        });
      }
    });

    const formData = new FormData();
    blobImages.forEach((blob, index) => {
      const currentImage = generatedImages[index];
      formData.append(
        'images',
        blob,
        index === 0
          ? `${productId}-primary-image-${index}-id=${currentImage.mockupId}`
          : `${productId}-image-${index}-id=${currentImage.mockupId}`,
      );
    });
    formData.append('myMockups', JSON.stringify(myMockups));
    const formDataObject: any = {};
    formData.forEach((value, key) => {
      if (key === 'images') {
        if (!formDataObject[key]) {
          formDataObject[key] = [];
        }
        formDataObject[key].push(value);
      } else {
        formDataObject[key] = value;
      }
    });

    if (naviagteToDetails) {
      navigate(`/details/${productId}`, {
        state: {
          selectedImageToShow: selectedImages.map((image: any) => ({
            imageUrl: image.mockupImage,
          })),
          mockupsDataToPerformUpload: {
            formDataObject,
            productId,
          },
        },
      });
    } else {
      await uploadMockupsMutation({
        formData,
        productId,
      })
        .unwrap()
        .then(({ data }) => {
          const mrsp = getMinimumAndMaximumMRSPFromVariants(
            data.variants as SingleProductVariant[],
          );
          const amplitudeData = {
            product_name: data.title,
            color: getUniqueColorsOrColorHexFromVariants(
              data.variants as SingleProductVariant[],
            ).join(', '),
            price: `USD ${mrsp.min} - ${mrsp.max}`,
            mockups: selectedImages.length,
          };
          amplitude.getInstance().logEvent('Save - Mockup', amplitudeData);
        })
        .catch(() => {});
      navigate(routeNames.myProducts);
    }
  };
  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      const data = [...selectedImages];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      dispatch(
        setClickedImage({
          imgId: item.mockupId,
          imageUrl: item.mockupImage,
        }),
      );
      dispatch(setSelectedImageArray(data));
    },
    nodeSelector: '.item-header',
    handleSelector: '.draggable',
  };
  useEffect(() => {
    dispatch(setProductId(productId));
    dispatch(setModelNumber(modelNumber));
    if (isSuccess && productData) {
      const { images } = productData;
      const selectedMockups = images.map((image: any) => ({
        mockupId: image.id,
        mockupImage: image.imageUrl,
        isMymockup: true,
      }));
      const newMockups = images.map((image: any) => ({
        id: image.id,
        image: image.imageUrl,
        selected: true,
      }));
      dispatch(setSelectedImageArray(selectedMockups));
      dispatch(updateMockups(newMockups));
      if (selectedMockups.length > 0) {
        dispatch(
          setClickedImage({
            imageUrl: selectedMockups[0].mockupImage,
            imgId: selectedMockups[0].mockupId,
          }),
        );
        // dispatch(setMockups(newMockups));
      }
    }
  }, [productId, modelNumber, dispatch, productData, isSuccess]);

  const uploadToMymockups = (acceptedFiles: any, inputRef?: any) => {
    if (acceptedFiles) {
      const image = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.addEventListener('load', async () => {
        try {
          if (reader.result) {
            const formData = new FormData();
            formData.set('images', image);
            const myMockupImageResponse = await addToMyMockups({
              formData,
            }).unwrap();
            const dataArray = myMockupImageResponse.data as unknown as any[];
            const newMockObj: MockupData = {
              id: dataArray[0].id,
              image: dataArray[0].imageUrl,
              selected: selectedImages.length < 10 ? true : false,
            };
            dispatch(updateMockupsInBeginning([newMockObj]));
            dispatch(increaseExtraMockupCount());
            dispatch(
              setSelectedImages({
                mockupId: dataArray[0].id,
                mockupImage: dataArray[0].imageUrl,
                isMymockup: true,
              }),
            );
          }
          if (inputRef?.current) inputRef.current.value = '';
        } catch (error) {}
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadToMymockups,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
    },
    disabled: selectedImages.length >= 10,
  });
  const placeholderIterator = Array(10 - selectedImages.length).fill(0);

  return (
    <>
      {(isLoading || isUploading || isMockupsUploading) && <Loader />}
      <Section isTablet={isTablet}>
        <MockupPreviewWrapper isTablet={isTablet}>
          <TopBar
            originalData={updatedMockupImage}
            onSave={() => uploadMockups(false)}
            currentPage="mockups"
          />
          <Wrapper isTablet={isTablet}>
            <ImagePlaceholder
              width={isTablet ? '560px' : '600px'}
              height={isTablet ? '560px' : '600px'}
              style={{ position: 'relative' }}
              borderColor={colors.grey[700]}
              isThereImage={selectedImages.length !== 0}
              mainImage
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {
                // isHighResolutionMockupLoading ? (
                //   <img src={LoaderGif} alt="loader" className="loader" />
                // ) :
                selectedImages.length > 0 ? (
                  <ImageBox
                    isClickable
                    className={
                      ProductType.Mug &&
                      clickedImage?.imgId?.toString().includes('snap')
                        ? ' mug-image-box'
                        : ''
                    }
                    height="600px"
                    mainImage
                    image={
                      clickedImage !== null
                        ? clickedImage.imageUrl
                        : selectedImages[selectedImages.length - 1].mockupImage
                    }
                  />
                ) : (
                  <FlexBox alignItems="center" direction="column" gap="1rem">
                    <img src={DropzonePlaceholder} alt="Placeholder" />
                    <div className="grey600">
                      Drag and Drop images here or select them on the right.
                    </div>
                  </FlexBox>
                )
              }
              {/* KEPT FOR FUTURE REFERENCE */}

              {/* <ActionButtons>
              <Button
                appearance="primary"
                icon={AddImage}
                label="Add Image"
                onClick={() => console.log('draft button clicked')}
              />
              <Button
                appearance="primary"
                icon={TextWhiteIcon}
                label="Add Text"
                onClick={() => console.log('draft button clicked')}
              />
            </ActionButtons> */}
            </ImagePlaceholder>

            <ReactDragListView {...dragProps}>
              <SideMockupsGroup className="show-no-scrollbar">
                {selectedImages?.map((item: SelectedImage, index: number) => {
                  return (
                    <ImageBox
                      isClickable
                      className={
                        ProductType.Mug &&
                        item.mockupId.toString().includes('snap')
                          ? ' mug-image-box item-header'
                          : 'item-header'
                      }
                      key={`${item.mockupId}-${index}`}
                      boxWidth="120px"
                      boxHeight="120px"
                      image={item.mockupImage}
                      itemId={item.mockupId}
                      leftScrollItem
                      deleteIcon={true}
                      onClick={() =>
                        handleClickedImage(item.mockupId, item.mockupImage)
                      }
                      index={index}
                    >
                      {index === 0 && (
                        <img
                          className="image-item crown"
                          width="28"
                          src={Crown}
                          alt="crown"
                        />
                      )}
                    </ImageBox>
                  );
                })}

                {placeholderIterator.map((_, index) => (
                  <ImagePlaceholderComponent
                    key={index}
                    uploadToMymockups={uploadToMymockups}
                  />
                ))}
              </SideMockupsGroup>
            </ReactDragListView>
          </Wrapper>
        </MockupPreviewWrapper>
        {isTablet && (
          <FlexBox justifyContent="space-between" className="button-wrapper">
            <Button
              label="Product Mockups"
              onClick={() => setOpenRightSidebar(true)}
              appearance="secondary"
              size="medium"
              icon={ProductMockups}
            />
            <div
              style={{ width: '20%' }}
              onClick={() => setOpenButtonDrawer(true)}
            >
              <Button
                label=""
                icon={RightArrowIcon}
                onClick={() => setOpenButtonDrawer(true)}
                appearance="primary"
                size="fullWidth"
              />
            </div>
          </FlexBox>
        )}
        <Drawer
          open={openButtonDrawer}
          anchor={'bottom'}
          PaperProps={{
            sx: {
              width: '100vw',
              height: '7%',
            },
          }}
          ModalProps={{
            disableEnforceFocus: true,
            disableAutoFocus: true,
            onBackdropClick: () => setOpenButtonDrawer(false),
          }}
        >
          <FlexBox
            style={{ margin: '11px 27px' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <span> What would you like to do now?</span>
            <CTAButtonGroup>
              <Button
                appearance="secondary"
                label="Save as Draft"
                disabled={
                  selectedImages.length === 0 || isHighResolutionMockupLoading
                }
                onClick={() => uploadMockups(false)}
              />
              <Button
                appearance="primary"
                label="Save & Continue"
                disabled={
                  selectedImages.length === 0 || isHighResolutionMockupLoading
                }
                onClick={() => uploadMockups(true)}
              />
            </CTAButtonGroup>
          </FlexBox>
        </Drawer>

        {/*Right Mockup Listing Section */}

        {productData && catalogData && (
          <SideBar>
            {isTablet ? (
              <Drawer
                open={openRightSidebar}
                anchor={'bottom'}
                PaperProps={{
                  sx: {
                    width: '100vw',
                    height: '100%',
                  },
                }}
              >
                <RightSideBar
                  variants={mockupVariants}
                  setIsHighResolutionMockupLoading={
                    setIsHighResolutionMockupLoading
                  }
                  isHighResolutionMockupLoading={isHighResolutionMockupLoading}
                  isTablet={isTablet}
                  onClose={() => setOpenRightSidebar(false)}
                  productType={catalogData.catalogProductInfo.editorType}
                  productName={catalogData.catalogProductInfo.editorSubType}
                  printSides={printSides}
                />
              </Drawer>
            ) : (
              <RightSideBar
                variants={mockupVariants}
                setIsHighResolutionMockupLoading={
                  setIsHighResolutionMockupLoading
                }
                isHighResolutionMockupLoading={isHighResolutionMockupLoading}
                isTablet={isTablet}
                productType={catalogData.catalogProductInfo.editorType}
                productName={catalogData.catalogProductInfo.editorSubType}
                printSides={printSides}
              />
            )}
          </SideBar>
        )}

        {/* Right Sidebar ends */}
      </Section>
    </>
  );
};

export default Mockups;

interface ImagePlaceHolderProps {
  uploadToMymockups: (
    files: FileList | null,
    inputRef?: React.RefObject<HTMLInputElement>,
  ) => void;
}
const ImagePlaceholderComponent: React.FC<ImagePlaceHolderProps> = ({
  uploadToMymockups,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <ImagePlaceholder onClick={handleClick}>
      <input
        multiple={false}
        onChange={(e) => uploadToMymockups(e.target.files, inputRef)}
        ref={inputRef}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
      />
      <img alt="Placeholder" src={DropzonePlaceholder} />
    </ImagePlaceholder>
  );
};

const Section = styled.div<{ isTablet: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isTablet ? 'column' : 'row')};
  height: 100vh;
  overflow: hidden;
  .loader {
    width: 35%;
    height: 20%;
  }
  .button-wrapper {
    padding: 12px;
  }
`;

const MockupPreviewWrapper = styled.div<{ isTablet: boolean }>`
  background-color: ${colors.grey[100]};
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isTablet ? '100%' : '75%')};
`;

const SideMockupsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100%;
  gap: 0.8rem;

  & > div {
    border-radius: 6px;
  }
`;

// const ActionButtons = styled.div`
//   position: absolute;
//   display: flex;
//   flex-flow: column;
//   bottom: 1.2rem;
//   right: 1.2rem;
// `;

const SideBar = styled.div`
  background-color: ${colors.white};
  width: 25%;
  position: relative;
  overflow: hidden;
  padding: 10px;
`;

const Wrapper = styled.div<{ isTablet: boolean }>`
  padding: 16px 32px;
  display: flex;
  overflow: hidden;
  margin: auto;
  height: ${(props) => (props.isTablet ? '84vh' : '600px')};
  width: ${(props) => (props.isTablet ? '93vw' : 'auto')};
  gap: 2.5rem;

  .div:first-child {
    position: relative;
  }

  .mockups-group--sm {
    overflow-y: scroll;
  }
`;
const CTAButtonGroup = styled.div`
  width: 40%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  & > button {
    flex: 1;
  }
`;
