import {
  GridColumnHeaderParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import styled from 'styled-components';
import { useMemo, useState } from 'react';
import { FlexBox } from '../components/common/wrappers';
import { colors } from '../assets/colors';
import {
  ArrowUpRightIcon,
  BrushIcon,
  DuplicateBlueIcon,
  EtsyIcon,
  LoaderGif,
  VerticalDotsIcon,
} from '../assets/icons';
import { ReactComponent as InfoIcon } from '../assets/images/info-icon.svg';
import CustomToolTip from '../components/custom-tooltip';
import MiniImageInfoSection from '../components/mini-image-info-section';
import { ColoredCircle } from '../components/common/elements';
import { Option, Status } from '../interface';
import MenuList from '../components/menu';
import Typography from '../components/typography';
import { OrderStatus } from '../interface/orders-interface';
import { useNavigate } from 'react-router-dom';
import { openLinkInNewTab } from '../helper/openLinkInNewTab';
import ImageBox from '../components/common/image-box';
import { getColorCodeByStatus } from './getColorCodeByStatus';
import { MyProductProperty } from '../interface/product-interface';

export const RenderText = (
  prop: GridRenderCellParams<any>,
  fontSize?: string,
) => {
  return (
    <Typography
      fontSize={fontSize ?? '12px'}
      text={prop.value ?? '--'}
      texTransform="capitalize"
      ellipsisText={true}
    />
  );
};

export const RenderTextHeader = (
  prop: GridColumnHeaderParams,
  fontSize?: string,
) => {
  return (
    <Typography
      fontSize={fontSize ?? '12px'}
      secondaryColorValue={700}
      text={prop.colDef.headerName ?? ''}
      ellipsisText={true}
    />
  );
};

export const RenderProduct = (prop: GridRenderCellParams<any, number>) => {
  return (
    <ProductWrapper>
      <FlexBox
        style={{ width: 80 }}
        justifyContent="center"
        alignItems="center"
      >
        {prop.row?.savingDesign || !prop.row?.image ? (
          <img height={50} src={LoaderGif} alt="Loading" />
        ) : (
          <img className="product-image" src={prop.row?.image} alt="product" />
        )}
      </FlexBox>
      <FlexBox direction="column" gap="1rem" wrap="wrap">
        <Typography
          fontSize="1rem"
          fontWeight="700"
          text={prop.value ?? '--'}
          ellipsisText={true}
        />
        <FlexBox gap="12px" alignItems="center" wrap="wrap">
          {prop.row?.properties?.map((property: MyProductProperty) => {
            const { propertyName, propertyValues } = property;

            return (
              <div key={propertyName}>
                {propertyName === 'color' ? (
                  <FlexBox gap="3px">
                    {propertyValues.slice(0, 5).map((propValue, index) => (
                      <ColoredCircle
                        key={index}
                        colorCode={
                          propValue.value.includes('/')
                            ? propValue.value
                            : `#${propValue?.meta?.colorHex}`
                        }
                        width={18}
                        height={18}
                      />
                    ))}
                    {propertyValues.length > 5 && (
                      <>
                        {propertyValues.length > 5 && (
                          <span key={propertyValues.length}>
                            +{propertyValues.length - 5} more
                          </span>
                        )}
                      </>
                    )}
                  </FlexBox>
                ) : (
                  <>
                    {propertyName === 'size' && (
                      <Typography
                        key={property.propertyName}
                        fontSize="1rem"
                        fontWeight="500"
                        text={`${propertyValues.length}  sizes`}
                        ellipsisText={true}
                      />
                    )}
                  </>
                )}
              </div>
            );
          })}
        </FlexBox>
      </FlexBox>
    </ProductWrapper>
  );
};

export const RenderTextWithColorMark = (
  prop: GridRenderCellParams<any, number>,
) => {
  if (prop.value) {
    const isAnyFieldMissingFromPublishedLisiting =
      prop.row?.isAnyFieldsMissing && prop.row?.listingId;
    return (
      <FlexBox gap="8px" alignItems="center">
        <ListColorMark
          status={
            isAnyFieldMissingFromPublishedLisiting
              ? Status.Error
              : prop.value?.toString()?.split('*')[0]
          }
        />
        {isAnyFieldMissingFromPublishedLisiting ? (
          <CustomToolTip title={'Few required fields are missing'}>
            <FlexBox alignItems="center">
              <Typography
                fontSize="14px"
                text={`${prop.value}`}
                ellipsisText={true}
                className="red-color"
              />
              <InfoIcon fontSize="1rem" />
            </FlexBox>
          </CustomToolTip>
        ) : (
          <Typography
            fontSize="14px"
            text={`${prop.value}`}
            ellipsisText={true}
          />
        )}
      </FlexBox>
    );
  }
};

export const RenderTextWithColorMarkAndHover = (
  prop: GridRenderCellParams<any, number>,
  isGroupByColor: boolean,
) => {
  if (prop.value) {
    const colorMark = (
      <FlexBox gap="8px" alignItems="center">
        {isGroupByColor && (
          <ColoredCircle
            colorCode={`#${prop.row?.colorHex}`}
            height={8}
            width={8}
          />
        )}
        <Typography fontSize="14px" text={prop.value} ellipsisText={true} />
      </FlexBox>
    );
    return isGroupByColor ? (
      <CustomToolTip
        placement="right"
        title={
          <ImageBox image={prop.row.imageUrl} width="150px" defaultCursor />
        }
      >
        {colorMark}
      </CustomToolTip>
    ) : (
      colorMark
    );
  }
};

export const RenderActionButtons: React.FC<{
  prop: GridRenderCellParams<any, number>;
  deleteProduct: (productId: string) => void;
  publishProduct: (
    productId: string,
    taxonomyId: number,
    publishChange: boolean,
  ) => void;
  duplicateProduct: (productId: string) => void;
  discardChangesHandler: (productId: string) => void;
  isTablet: boolean;
}> = ({
  prop,
  deleteProduct,
  publishProduct,
  duplicateProduct,
  discardChangesHandler,
  isTablet,
}) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const isAnyFieldMissingFromPublishedLisiting =
    prop.row?.isAnyFieldsMissing && prop.row?.listingId;

  const actionButtons = useMemo(() => {
    let options = [];
    const productStatus = prop.row.status;
    const listingId = prop.row.listingId;
    if (listingId && isTablet) {
      options.push({ label: 'View on Etsy', value: 'viewOnEtsy' });
    }

    if (
      productStatus === Status.UnpublishedChanges &&
      !isAnyFieldMissingFromPublishedLisiting
    ) {
      options.push({ label: 'Publish changes', value: 'publishChanges' });
    } else if (productStatus === Status.Unpublished) {
      options.push({ label: 'Publish', value: 'publish' });
    }
    if (productStatus === Status.UnpublishedChanges && listingId) {
      options.push({
        label: 'Discard unpublished changes',
        value: 'discardChanges',
      });
    }
    options = [...options, ...(isTablet ? buttonItems : actionButtonOptions)];
    return options;
  }, [
    isTablet,
    prop.row.listingId,
    prop.row.status,
    isAnyFieldMissingFromPublishedLisiting,
  ]);

  const onClickHandler = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    actionType: string,
  ) => {
    e.stopPropagation();
    if (actionType === 'edit') {
      navigate(
        `/editor/${prop.row.catalogId}/${prop.row.printProviderId}/${prop.id}`,
      );
    } else if (actionType === 'duplicate') {
      duplicateProduct(prop.id as string);
    } else if (actionType === 'viewOnEtsy') {
      openLinkInNewTab(`https://www.etsy.com/listing/${prop.row.listingId}`);
    }
  };

  const onChangeMenu = (value: string) => {
    if (value === 'delete') {
      deleteProduct(prop.id as string);
    }
    if (value === 'publish') {
      publishProduct(prop.id as string, prop.row.taxonomyId, false);
    }
    if (value === 'publishChanges') {
      publishProduct(prop.id as string, prop.row.taxonomyId, true);
    }
    if (value === 'edit') {
      navigate(
        `/editor/${prop.row.catalogProductId}/${prop.row.printProviderId}/${prop.id}`,
      );
    }
    if (value === 'duplicate') {
      duplicateProduct(prop.id as string);
    }
    if (value === 'discardChanges') {
      discardChangesHandler(prop.id as string);
    }
    if (value === 'viewOnEtsy') {
      openLinkInNewTab(`https://www.etsy.com/listing/${prop.row.listingId}`);
    }
  };

  return (
    <>
      {isTablet ? (
        <ActionButtonWrapper isTablet={isTablet}>
          <div
            className="action-buttons"
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup((prev) => !prev);
            }}
          >
            <img src={VerticalDotsIcon} alt="menu" />
            {showPopup && (
              <MenuList
                className="action-button-menu"
                right="0px"
                items={actionButtons}
                onClick={(value) => onChangeMenu(value)}
              />
            )}
          </div>
        </ActionButtonWrapper>
      ) : (
        <ActionButtonWrapper
          isTablet={false}
          onMouseLeave={() => setShowPopup(false)}
        >
          <div
            className="action-buttons"
            onClick={(e) => onClickHandler(e, 'edit')}
          >
            <CustomToolTip placement="top" title="Edit design">
              <img src={BrushIcon} alt="edit" />
            </CustomToolTip>
          </div>
          <div
            className="action-buttons"
            onClick={(e) => {
              onClickHandler(e, 'duplicate');
            }}
          >
            <CustomToolTip placement="top" title="Duplicate">
              <img src={DuplicateBlueIcon} alt="copy" />
            </CustomToolTip>
          </div>
          {prop.row.listingId && (
            <div
              className="action-buttons"
              onClick={(e) => onClickHandler(e, 'viewOnEtsy')}
            >
              <CustomToolTip placement="top" title="View on Etsy">
                <img
                  src={ArrowUpRightIcon}
                  alt="view-on-etsy"
                  className="m-l-2px"
                />
              </CustomToolTip>
            </div>
          )}

          <div
            className="action-buttons position-relative"
            onClick={(e) => {
              e.stopPropagation();
              setShowPopup((prev) => !prev);
            }}
          >
            <img src={VerticalDotsIcon} alt="menu" />
            {showPopup && (
              <MenuList
                className="action-button-menu"
                right="0px"
                items={actionButtons}
                onClick={(value) => onChangeMenu(value)}
              />
            )}
          </div>
        </ActionButtonWrapper>
      )}
    </>
  );
};

export const RenderImage = (prop: GridRenderCellParams<any, number>) => {
  return <Image src={EtsyIcon} alt="store" />;
};

export const RenderOrderInfo = (prop: GridRenderCellParams<any, number>) => {
  if (prop.value) {
    return (
      <CustomToolTip
        title={
          <MiniImageInfoSection
            imageUrls={prop.row.imageUrls}
            productColor={prop.row.productDetails.colorHex}
            quantity={prop.row.productDetails.quantity}
            productSize={prop.row.productDetails.size}
            forHover={true}
          />
        }
      >
        <FlexBox direction="column" gap="2px">
          <FlexBox alignItems="center" justifyContent="space-between">
            <Typography
              fontSize="1rem"
              fontWeight="700"
              secondaryColorValue={900}
              text={prop.value ?? '---'}
              ellipsisText={true}
            />
            {prop.row.isSampleOrder && (
              <Typography
                fontSize="12px"
                text="Sample Order"
                className="sample-order-banner b-r-6px"
              />
            )}
          </FlexBox>
          <Typography
            fontSize="14px"
            secondaryColorValue={500}
            text={`Created ${prop.row.createdAt}`}
            ellipsisText={true}
          />
        </FlexBox>
      </CustomToolTip>
    );
  } else {
    <Typography text="---" fontSize="12px" />;
  }
};

export const RenderCellWithCurrency = (
  prop: GridRenderCellParams<any, number>,
) => {
  return (
    <Typography
      fontSize="14px"
      secondaryColorValue={900}
      text={`USD ${prop.value}`}
      ellipsisText={true}
    />
  );
};

export const RenderTracking = (prop: GridRenderCellParams<any, number>) => {
  return <ColoredBox>Pending</ColoredBox>;
};

export const RenderStatus: React.FC<{
  prop: GridRenderCellParams<any, number>;
}> = ({ prop }) => {
  return (
    <FlexBox alignItems="baseline" gap="8px">
      <ListColorMark status={prop.value as any} />
      <FlexBox gap="2px" direction="column">
        <Typography fontSize="14px" text={prop.value} ellipsisText={true} />
        {(prop.value as any) !== OrderStatus.READY && (
          <Typography
            fontSize="12px"
            secondaryColorValue={500}
            text={
              (prop.value as any) === OrderStatus.SHIPPED
                ? prop.row.shippedOn
                : ''
            }
            ellipsisText={true}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export const RenderColorVariants = (
  prop: GridRenderCellParams<any, number>,
) => {
  if (prop.row.count) {
    return (
      <Typography text={prop.row.count} fontSize="12px" ellipsisText={true} />
    );
  } else {
    const hierarchy = prop.row.hierarchy as string[];
    return (
      <Typography
        text={hierarchy[hierarchy.length - 1]}
        fontSize="12px"
        ellipsisText={true}
      />
    );
  }
};

export const RenderInventory = (prop: GridRenderCellParams<any, number>) => (
  <Typography fontSize="14px" ellipsisText={true} text={prop.value ?? '---'} />
);
/** Styles */

const ProductWrapper = styled.div`
  display: flex;
  gap: 1rem;
  .product-image {
    height: 80px;
    width: 80px;
    border-radius: 6px;
    object-fit: contain;
  }
`;

const ActionButtonWrapper = styled.div<{ isTablet: boolean }>`
  display: flex;
  gap: 10px;
  align-items: center;
  position: ${(props) => (props.isTablet ? 'static' : 'absolute')};
  .action-buttons {
    padding: 6px;
    background-color: ${colors.white};
    border: 1px solid ${colors.grey.border};
    border-radius: 6px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    visibility: ${(props) => (props.isTablet ? 'visible' : 'hidden')};
    .m-l-2px {
      margin-left: -2px;
    }
  }
  .action-button-menu {
    .menu-item {
      :last-child {
        color: ${colors.red[600]};
      }
    }
  }
  .position-relative {
    position: relative;
  }
`;

const ListColorMark = styled.div<{
  status: string;
}>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => getColorCodeByStatus(props.status)};
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
`;

const ColoredBox = styled.div<{ color?: string }>`
  min-width: fit-content;
  padding: 4px 10px;
  border-radius: 6px;
  color: ${colors.grey['800']};
  font-size: 14px;
  background-color: ${(props) =>
    props.color ? props.color : colors.grey[300]};
`;

const actionButtonOptions: Option[] = [
  // { label: 'Replace', value: 'replace' },
  // { label: 'Copy to', value: 'copy_to' },
  { label: 'Delete', value: 'delete' },
];

const buttonItems: Option[] = [
  { label: 'Edit', value: 'edit' },
  { label: 'Duplicate', value: 'duplicate' },
  { label: 'Delete', value: 'delete' },
];
